import React from 'react';
import CustomCard from '../components/card';
import { Grid, Box } from '@mui/material';

const { cardsData } = require('../data/girls');

function HomePage() {
  return (
    <Box sx={{ flexGrow: 1, p: 2 }}>
      <Grid
        container
        spacing={2}
        justifyContent="center" // Allinea orizzontalmente al centro
        alignItems="center"     // Allinea verticalmente al centro
      >
        {cardsData.map((card, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <CustomCard
              name={card.name}
              mainImage={card.mainImage}
              overlayImage={card.overlayImage}
              buttonImage={card.buttonImage}
              offers={card.offers}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default HomePage;
