import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Box, Grid, Typography } from '@mui/material';
import { styled, keyframes } from '@mui/system';

const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
}));

const CustomButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'black',
  color: 'white',
  border: 'none',
  padding: '3px 10px',
  margin: '0 2px',
  cursor: 'pointer',
  fontSize: '20px',
  lineHeight: 1,
  transition: 'background-color 0.3s ease',
  '&:hover': {
    backgroundColor: 'rgba(0, 173, 239, 0.6)',
    transform: 'scale(1.05)',
  },
}));

const stripeAnimation = keyframes`
  0%, 100% {
    background-position: 0 0;
  }
  50% {
    background-position: 100% 0;
  }
`;

const AnimatedContainer = styled(Container)`
  background-color: black;
  padding: 0;
  text-align: center;
  margin-bottom: 30px;
  min-height: auto;
  position: relative;
  border-bottom: 1px solid #00ADEF;
`;

const AlertBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#ff4d4d', // Rosso acceso
  color: 'white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '10px 0',
  marginTop: '10px',
  borderTop: '1px solid #ff1a1a',
  borderBottom: '1px solid #ff1a1a',
  fontSize: '18px', // Testo leggermente più grande per renderlo più evidente
}));

function Menu() {
  return (
    <>
      <AnimatedContainer>
        <Grid container spacing={0} alignItems="center" sx={{ padding: '2px 0' }}>
          <Grid item xs={12} sm={4} sx={{ display: 'flex', justifyContent: 'center', padding: '0' }}>
            <StyledLink to="/">
              <img
                src="/img/test.png"
                alt="ContenutiSbloccabili.online"
                style={{ maxWidth: '100%', height: 'auto', maxHeight: '80px' }}
              />
            </StyledLink>
          </Grid>

          <Grid item xs={12} sm={8} sx={{ padding: '0' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: { xs: 'center', sm: 'flex-start' },
                flexWrap: 'wrap',
                padding: '0',
                marginLeft: { xs: '0px', sm: '60px' }, // Margine sinistro diverso per mobile e desktop
              }}
            >
              <StyledLink to="/">
                <CustomButton>Creators</CustomButton>
              </StyledLink>
              <StyledLink to="/come-fare">
                <CustomButton>Come fare</CustomButton>
              </StyledLink>
            </Box>
          </Grid>
        </Grid>
      </AnimatedContainer>

      {/* Avviso con emoji di allerta */}
      <AlertBox>
        <Typography variant="body1" component="span" sx={{ marginRight: '8px' }}>
          ⚠️
        </Typography>
        <Typography variant="body1" component="span">
          QUESTI CONTENUTI SONO 100% GRATIS
        </Typography>
      </AlertBox>
    </>
  );
}

export default Menu;

