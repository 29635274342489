import React from 'react';
import { Card, Typography, Box } from '@mui/material';
import { styled } from '@mui/system';

// Componente base per lo stile
const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: '100%', // Imposta larghezza massima al 100% per adattarsi agli schermi piccoli
  margin: 'auto',
  marginTop: theme.spacing(4),
  padding: theme.spacing(2), // Ridotto il padding per schermi più piccoli
  backgroundColor: '#222', // Colore di sfondo meno intenso
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(4), // Maggiore padding su schermi più grandi
  },
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  textAlign: 'center',
  fontWeight: 'bold',
  color: '#fff',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.5rem', // Ridurre la dimensione del testo su schermi piccoli
  },
}));

const StyledContent = styled(Typography)(({ theme }) => ({
  lineHeight: 1.6,
  textAlign: 'justify',
  color: '#f0f0f0',
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.875rem', // Ridurre la dimensione del testo su schermi piccoli
  },
}));

// Componente generico
const InfoCard = ({ title, children }) => {
  return (
    <StyledCard>
      <StyledTitle variant="h4">{title}</StyledTitle>
      <StyledContent variant="body1">{children}</StyledContent>
    </StyledCard>
  );
};

export default InfoCard;
