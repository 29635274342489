import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import TOS from './components/tos';
import ComeFare from './components/comefare';
import Menu from './components/menu';
import Footer from './components/footer';
import './App.css';

function App() {
  const appStyle = {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  };

  const contentStyle = {
    flex: '1', // Occupa tutto lo spazio disponibile, spingendo il footer in basso
  };

  return (
    <div style={appStyle}>
      <Router>
        <Menu />
        <div style={contentStyle}>
          <Routes>
            <Route path="/tos" element={<TOS />} />
            <Route path="/come-fare" element={<ComeFare />} />
            <Route path="/" element={<HomePage />} />
            {/* Aggiungi altre rotte se necessario */}
          </Routes>
        </div>
        <Footer />
      </Router>
    </div>
  );
}


export default App;
