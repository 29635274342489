const {offers_oliva, offers_laura, offers_elena, offers_chiara, offers_giorgia} = require('./offers');

const cardsData = [
  {
    name: 'Olivia',
    mainImage: 'img/olivia.jpg',
    overlayImage: 'img/content-nore.png',
    buttonImage: '',
    offers: offers_oliva
  },
  {
    name: 'Laura',
    mainImage: 'img/laura.jpg',
    overlayImage: 'img/content-nore.png',
    buttonImage: '',
    offers: offers_laura
  },
   {
    name: 'Elena',
    mainImage: 'img/elena.jpg',
    overlayImage: 'img/content-nore.png',
    buttonImage: '',
    offers: offers_elena
  },
  {
    name: 'Chiara',
    mainImage: 'img/chiara.jpg',
    overlayImage: 'img/content-nore.png',
    buttonImage: '',
    offers: offers_chiara
  },
  {
    name: 'Giorgia',
    mainImage: 'img/giorgia.jpg',
    overlayImage: 'img/content-nore.png',
    buttonImage: '',
    offers: offers_giorgia
  }

];


module.exports = {
  cardsData
}

