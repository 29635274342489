import React, { useState } from 'react';
import {
  Card,
  Typography,
  IconButton,
  Drawer,
  Box,
  Grid,
  Avatar,
  Modal
} from '@mui/material';
import { styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { keyframes } from '@mui/system';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import InfoIcon from '@mui/icons-material/Info';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';

// Definizione globale delle animazioni
const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const pulse = keyframes`
0% {
   transform: translateY(0) scale(1);
   opacity: 1;
 }
 40% {
   transform: translateY(5px) scale(1.05); /* Sposta verso l'alto di 5px e aumenta la scala */
   opacity: 1;
 }
 60% {
   transform: translateY(10px) scale(1.1); /* Sposta verso l'alto di 10px e aumenta la scala */
   opacity: 1;
 }
 80% {
   transform: translateY(15px) scale(1.15); /* Torna alla posizione intermedia */
   opacity: 1;
 }
 100% {
   transform: translateY(0) scale(1); /* Torna alla posizione originale e scala */
   opacity: 1;
 }
`;

const hoverAnimation = keyframes`
  0% {
    transform: scale(1.05);
  }
  25%{
    transform: scale(1);
  }
  50%{
    transform: scale(1.05);
  }
  75%{
    transform: scale(1);
  }
  100% {
    transform: scale(1.05);
  }
`;

const shakeAnimation = keyframes`
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
`;

// Componente base per la Card
const StyledCard = styled(Card)(({ theme }) => ({
  position: 'relative',
  maxWidth: 345,
  height: 500,
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: 20,
  overflow: 'hidden',
}));

// Contenitore dell'immagine
const ImageContainer = styled(Box)({
  position: 'relative',
  flex: 1,
  overflow: 'hidden',
});

// Immagine sfocata
const BlurredImage = styled('img')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  filter: 'blur(3px)',
  transform: 'scale(1.1)',
});

// Immagine di overlay
const OverlayImage = styled('img')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'contain',
  zIndex: 1,

});

// Nome della Card
const CardName = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  left: '50%',
  transform: 'translateX(-50%)',
  color: 'rgb(66,184,250)',
  textShadow: '1px 1px 3px rgba(0,0,0,0.8)',
  zIndex: 2,
  textAlign: 'center',
  backgroundColor: 'rgba(0,0,0,0.55)',
  width: '150px',
  borderRadius: '5px',
  fontWeight: 'bolder'
}));

// Pulsante di sblocco con animazione pulse
const UnlockButton = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: theme.spacing(2),
  left: '50%',
  transform: 'translateX(-50%)',
  zIndex: 2,
  backgroundColor: 'black', // Colore di sfondo nero
  color: 'rgb(66,184,250)', // Colore del testo celeste
  borderRadius: 4,
  width: 300,
  height: 50,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  textAlign: 'center',
  fontWeight: 'bold',
  fontSize: '16px',
  transition: 'background-color 0.3s ease, color 0.3s ease', // Transizione per l'hover
  borderRadius: 20,
  fontWeight: 'bold',

  // Applicazione dell'animazione hover
  '&:hover': {
    backgroundColor: 'rgb(66,184,250)', // Cambia il colore di sfondo al passaggio del mouse
    color: 'black', // Cambia il colore del testo al passaggio del mouse
    fontWeight: "bold",
    fontSize: "1.2rem",
    animation: `${hoverAnimation} 5s infinite`,
    left: 20
  },
}));

const OfferAvatar = styled(Avatar)(({ theme, gradientStart, gradientEnd }) => ({
  width: theme.spacing(10),
  height: theme.spacing(10),
  margin: 'auto',
  cursor: 'pointer',
  border: `3px solid transparent`,
  borderRadius: '50%',
  backgroundImage: `linear-gradient(${gradientStart}, ${gradientEnd})`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& img': {
    borderRadius: '50%',
  },
  '&:hover': {
    opacity: 0.8,
  },
  '&:before': {
    content: '""',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: -1,
    borderRadius: '50%',
    padding: '2px',
    backgroundImage: `linear-gradient(${gradientStart}, ${gradientEnd})`,
    WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
    WebkitMaskComposite: 'destination-out',
    maskComposite: 'exclude',
  },
}));

const CustomCard = ({ name, mainImage, overlayImage, offers }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState(null);

  const handleOpenDrawer = () => setIsDrawerOpen(true);
  const handleCloseDrawer = () => setIsDrawerOpen(false);

  const handleOfferClick = (offer) => {
    setSelectedOffer(offer);
  };

  const handleCloseModal = () => setSelectedOffer(null);

  return (
    <StyledCard>
      <CardName variant="h5">{name}</CardName>
      <ImageContainer>
        <BlurredImage src={mainImage} alt="Blurred main image" />
        <OverlayImage src={overlayImage} alt="Overlay" />
      </ImageContainer>
      <Box
    sx={{
      position: 'absolute',
      bottom: 80, // Posiziona la freccia sopra il pulsante
      left: '40%',
      transform: 'translateX(-50%)',
      animation: `${pulse} 1.5s infinite`,
    }}
  >
    <KeyboardArrowDownIcon
      sx={{
        fontSize: 80,
        color: 'rgb(66,184,250)',
        animation: `${pulse} 1.5s infinite`,
        textShadow: `0 0 6px rgba(255, 255, 255, 0.8)`,
      }}
    />
  </Box>
      <UnlockButton onClick={handleOpenDrawer}>
        Premi qui per sbloccare
      </UnlockButton>
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={handleCloseDrawer}
        PaperProps={{
          sx: { width: { xs: '100%', sm: 450, md: 600 },  // Imposta la larghezza per diverse dimensioni di schermo
    backgroundColor: 'black',
    color: 'white', }
        }}
      >
        <Box p={2} sx={{ textAlign: 'center' }}>
          <Typography variant="h6" component="h2" gutterBottom>
          <LocalOfferIcon sx={{ color: '#00ADEF', fontSize: 25,  verticalAlign: 'middle'}} />  Offerte disponibili
          </Typography>
          <Grid container spacing={2}>
            {offers.map((offer, index) => (
              <Grid item xs={6} sm={3} key={index}>  {/* 6 su XS (2 per riga), 3 su SM e superiori (4 per riga) */}
                <OfferAvatar
                  src={offer.image}
                  alt={offer.name}
                  gradientStart={offer.gradientStart}
                  gradientEnd={offer.gradientEnd}
                  onClick={() => handleOfferClick(offer)}
                />
                <Typography variant="body2" align="center" mt={1} fontWeight="bold">
                  {offer.name}
                </Typography>
              </Grid>
            ))}
          </Grid>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <IconButton onClick={handleCloseDrawer}>
              <CloseIcon sx={{ fontSize: 24, color: 'red' }} />
            </IconButton>
          </Box>
        </Box>
      </Drawer>
      <Modal
  open={!!selectedOffer}
  onClose={handleCloseModal}
  aria-labelledby="offer-modal-title"
  sx={{
    '& .MuiPaper-root': {
      border: 'none',
      outline: 'none',
    },
  }}
>
  <Box
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'black',
      color: 'white',
      boxShadow: 24,
      p: 4,
      borderRadius: 2,
      outline: 'none',
    }}
  >
    {selectedOffer && (
      <>
        <Typography
          id="offer-modal-title"
          variant="h6"
          component="h2"
          gutterBottom
          align="center"
          fontWeight="bold"
        >
          {selectedOffer.name}
        </Typography>
        <Box sx={{ textAlign: 'center' }}>
        <Box
        sx={{
          position: 'relative',
          width: '100%',
          borderRadius: '50%',  // Arrotonda lo sfondo
          overflow: 'hidden',
          backgroundImage: `linear-gradient(${selectedOffer.gradientStart}, ${selectedOffer.gradientEnd})`, // Applica il gradiente come sfondo
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <img
          src={selectedOffer.image}
          alt={selectedOffer.name}
          style={{
            width: '100%',
            height: 'auto',
            borderRadius: '10px', // Arrotonda l'immagine
            position: 'relative',
            zIndex: 1, // Posiziona l'immagine sopra lo sfondo
          }}
        />
      </Box>
          <Typography variant="body2" mt={2}>
            <InfoIcon sx={{ color: 'primary.main', verticalAlign: 'middle', marginRight: '10px' }} />
            {selectedOffer.description}
          </Typography>

          <Box sx={{ mt: 2 }}>
            <IconButton
              component="a"
              href={selectedOffer.link} // Aggiungi il link all'offerta
              target="_blank" // Apre in una nuova scheda
              rel="noopener noreferrer" // Sicurezza per evitare problemi di sicurezza
              sx={{
                bgcolor: 'black',
                color: 'rgb(66,184,250)',
                p: 1,
                border: '1px solid rgb(66,184,250)',
                borderRadius: '4px',
                fontWeight: 'bold',
                '&:hover': {
                  bgcolor: 'rgb(66,184,250)',
                  color: 'black',

                },
              }}
            >
              Vai all'offerta
            </IconButton>
          </Box>
        </Box>
      </>
    )}
    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
      <IconButton onClick={handleCloseModal}>
        <CloseIcon sx={{ fontSize: 24, color: 'red' }} />
      </IconButton>
    </Box>
  </Box>
</Modal>

    </StyledCard>
  );
};

export default CustomCard;
