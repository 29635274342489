import React from 'react';

function Footer() {
  const footerStyle = {
    backgroundColor: '#000', // Sfondo scuro
    color: 'white',          // Testo chiaro
    padding: '20px',
    textAlign: 'center',
  };

  const linkStyle = {
    color: 'white',
    textDecoration: 'none',
    margin: '0 10px',
  };

  return (
    <footer style={footerStyle}>
      <div>
        <a href="/" style={linkStyle}><p>&copy; {new Date().getFullYear()} latuaragazza. Tutti i diritti riservati.</p></a>
      </div>
    </footer>
  );
}

export default Footer;
