import React from 'react';
import InfoCard from './infocard';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import CircleIcon from '@mui/icons-material/Circle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

const ComeFare = () => {
  return (
    <InfoCard title="❤️ LE OFFERTE ❤️">
      <Box sx={{ mb: 4 }}>
        <Typography variant="body1" paragraph>
          Le offerte sono delle semplici registrazioni a siti, in modo totalmente gratuito, attraverso i quali potrete ricevere delle ricompense direttamente da me in chat!
        </Typography>
      </Box>

      <Typography variant="h4" component="h2" align="center" sx={{ mb: 2 }}>
        COME FUNZIONA❓
      </Typography>
      <Divider sx={{ mb: 3, background: '#00ADEF' }} />

      <List sx={{ listStyleType: 'decimal', pl: 4 }}>
        <ListItem sx={{ alignItems: 'flex-start', pl: 0 }}>
          <ListItemIcon sx={{ minWidth: '30px', color: 'primary.main' }}>1.</ListItemIcon>
          <ListItemText
            primary={
              <>
                Scrivermi in privato:
                <Button
                  variant="contained"
                  color="primary"
                  href="https://t.me/BabyOliviaaa"
                  target="_blank"
                  rel="noopener noreferrer"
                  size="small"
                  sx={{ ml: 1 }}
                >
                  Contatta @BabyOliviaaa
                </Button>
              </>
            }
          />
        </ListItem>
        <ListItem sx={{ alignItems: 'flex-start', pl: 0 }}>
          <ListItemIcon sx={{ minWidth: '30px', color: 'primary.main' }}>2.</ListItemIcon>
          <ListItemText
            primary={
              <>
                Dirmi quale servizio dal listino:
                <Button
                  variant="contained"
                  color="secondary"
                  href="https://t.me/ListinoOlivia"
                  target="_blank"
                  rel="noopener noreferrer"
                  size="small"
                  sx={{ ml: 1 }}
                >
                  Vai a @ListinoOlivia
                </Button>
              </>
            }
          />
        </ListItem>
        <ListItem sx={{ pl: 0 }}>
          <ListItemIcon sx={{ minWidth: '30px', color: 'primary.main' }}>3.</ListItemIcon>
          <ListItemText
            primary="Vi invierò i link dei siti a cui registrarvi. Una volta effettuato, si parla di 2/3 minuti nei casi più veloci, potrete ricevere il vostro regalino 🎁"
          />
        </ListItem>
        <ListItem sx={{ pl: 0 }}>
          <ListItemIcon sx={{ minWidth: '30px', color: 'primary.main' }}>4.</ListItemIcon>
          <ListItemText
            primary="Tutto ciò è sempre valido! Potete tornare quando volete e soddisferò i vostri desideri 💡"
          />
        </ListItem>
      </List>

      <Typography variant="h4" component="h3" align="center" sx={{ mt: 4, mb: 2 }}>
        INFORMAZIONI UTILI❓
      </Typography>
      <Divider sx={{ mb: 3, background: '#00ADEF'}} />

      <List>
        <ListItem>
          <ListItemIcon sx={{ color: 'error.main' }}>
            <CancelIcon />
          </ListItemIcon>
          <ListItemText
            primary="Non farò nessun servizio prima delle registrazioni, INSISTERE = BLOCCO + SEGNALAZIONE"
          />
        </ListItem>
        <ListItem>
          <ListItemIcon sx={{ color: 'primary.main' }}>
            <CheckCircleIcon />
          </ListItemIcon>
          <ListItemText
            primary="Si potranno scegliere sia siti che richiedano la conferma via mail sia siti senza conferma via mail"
          />
        </ListItem>
        <ListItem>
          <ListItemIcon sx={{ color: 'primary.main' }}>
            <CheckCircleIcon />
          </ListItemIcon>
          <ListItemText
            primary="Questi siti non vi invieranno MAI mail pubblicitarie!"
          />
        </ListItem>
        <ListItem>
          <ListItemIcon sx={{ color: 'primary.main' }}>
            <CheckCircleIcon />
          </ListItemIcon>
          <ListItemText
            primary="Questi siti non vi accrediteranno nessun costo nel tempo"
          />
        </ListItem>
        <ListItem>
          <ListItemIcon sx={{ color: 'primary.main' }}>
            <CheckCircleIcon />
          </ListItemIcon>
          <ListItemText
            primary="Potete iscrivervi con dati totalmente casuali se non volete rivelare la vostra identità"
          />
        </ListItem>
      </List>
    </InfoCard>
  );
};

export default ComeFare;
